import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../context/AuthContext";
import LogoutButton from '../components/LogoutButton'
import { firestore } from "./../firebase";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';

const Home = () => {

    const [ emailFieldValue, setEmailFieldValue ] = useState();
    const { loggedInUser } = useContext(AuthContext);

    useEffect(() => {
        const unsubscribe = firestore.collection('user-profiles').doc(loggedInUser.uid).onSnapshot(snapshot => {
            setEmailFieldValue(snapshot.data().email);
        });
        return  () => unsubscribe();
    }, []);

    const submitEmail = async () => {
        await firestore.collection('user-profiles').doc(loggedInUser.uid).update({ email: emailFieldValue });
    }

    return (
        <Grid container direction="row">
                <Grid item xs={6} style={{padding:"20px"}}>
                HOME
                </Grid>
                <Grid item xs={6} container alignItems="flex-start" justify="flex-end" direction="row" style={{padding:"20px"}}>
                    <LogoutButton />
                </Grid>
            <Grid item xs={6} container alignItems="flex-start" justify="flex-end" direction="row" style={{padding:"10px"}}>
            <TextField
                type="text"
                placeholder="Email Address"
                value={emailFieldValue}
                onChange={(e) => setEmailFieldValue(e.target.value)}
            />
            </Grid>
            <Grid item xs={6}>
                <Button color="primary" variant="contained" onClick={() => submitEmail()}>SAVE EMAIL</Button>
            </Grid>
        </Grid>
    );
}

export default Home;