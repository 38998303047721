import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./views/Home";
import Login from "./views/Login";
import AssessmentForm from "./views/AssessmentForm";
import CollectionListing from "./components/CollectionListing.jsx";
import CollectionEdit from "./components/CollectionEdit.jsx";

import collections from './commons/collections';
import SideNav from "./components/SideNav";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Grid container>
          <Grid item xs="2">
            <SideNav />
          </Grid>
          <Grid item xs="10">
            <div className="App">
              <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute
                  exact
                  path="/assessments/edit/:assessmentID"
                  component={AssessmentForm}
                />
                <PrivateRoute
                  exact
                  path="/assessments/create"
                  component={AssessmentForm}
                />
                {
                  collections.map((collection) => {
                    const {
                      urlKey,
                    } = collection;
                    return <PrivateRoute exact path={`/${urlKey}`} component={() => <CollectionListing {...collection} />}>
                    </PrivateRoute>;
                  })
                }
                {
                  collections.map((collection) => {
                    const {
                      urlKey,
                      enableEdit,
                      dontShowRoute = false,
                    } = collection;
                    if (!enableEdit && dontShowRoute) return;
                    return <PrivateRoute
                      exact path={`/${urlKey}/edit/:moduleId/`}
                      component={() => <CollectionEdit {...collection} />}>
                    </PrivateRoute>;
                  })
                }
                {
                  collections.map((collection) => {
                    const {
                      moduleName,
                      enableCreate,
                      dontShowRoute = false,
                    } = collection;
                    if (!enableCreate && dontShowRoute) return;
                    return <PrivateRoute
                      exact path={`/${moduleName}/create/`}
                      component={() => <CollectionEdit {...collection} />}>
                    </PrivateRoute>;
                  })
                }
              </Switch>
            </div>
          </Grid>
        </Grid>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
