let SERVICES_BASE_URL, BASE_URL;

if (process.env.REACT_APP_ENV === "PRODUCTION") {
  BASE_URL = "https://masterlife-app.appspot.com";
  SERVICES_BASE_URL =
    "https://masterlife-services-dot-masterlife-app.appspot.com";
} else if (process.env.REACT_APP_ENV === "STAGING") {
  BASE_URL = "https://masterlife-staging.el.r.appspot.com";
  SERVICES_BASE_URL =
    "https://masterlife-services-dot-masterlife-staging.el.r.appspot.com";
} else {
  BASE_URL = "http://localhost:7000";
  SERVICES_BASE_URL = "http://localhost:7000";
}

let config = {
  MODULE_THUMBNAIL_URL: `${SERVICES_BASE_URL}/thumbnail/videoThumb/`,
  ASSESSMENT_REPORTING_URL: `${SERVICES_BASE_URL}/assessment-submission-csv/csv/`,
  USER_PROFILE_URL: `${BASE_URL}/user/all`,
  SEARCH_USER_URL: `${BASE_URL}/user/search_user_index`,
  GET_COURSES_URL: `${BASE_URL}/user/get_courses`,
  USER_COURSES_URL: `${BASE_URL}/user/get_courses_for_user`,
  BUY_COURSE_URL: `${BASE_URL}/user/buy_course`,
  CLONE_COURSE_URL: `${SERVICES_BASE_URL}/course/clone_course`,
  INSTANCE_COURSE_URL: `${BASE_URL}/user/get_instances_for_course`,
  USER_INFO_CSV: `${SERVICES_BASE_URL}/users/get_csv`,
};

export default config;
