import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import axios from "axios";
import config from "../commons/config";

export default ({ moduleId }) => {
  const [courseList, setCourseList] = useState([]);
  const [instanceList, setInstanceList] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [addCourseBtn, setStatusBtn] = useState(false);
  const [toastInfo, setToastInfo] = useState({
    show: false,
    type: "error",
    message: "",
  });

  useEffect(() => {
    getCoursesList();
    getCoursesForUser(moduleId);
  }, []);

  const getCoursesList = () => {
    axios
      .get(config.GET_COURSES_URL)
      .then((res) => {
        setCourseList([...res.data]);
      })
      .catch((error) => {
        console.log(error.response);
        let { data } = error.response;
        setCourseList([]);
        setToastInfo({ show: true, type: "error", message: data.message });
      });
  };
  const getInstancesForCourse = (courseId) => {
    axios
      .get(config.INSTANCE_COURSE_URL, {
        params: {
          course_id: courseId,
        },
      })
      .then((res) => {
        console.log(res);
        setSelectedInstance("");
        setInstanceList([...res.data]);
      })
      .catch((error) => {
        console.log(error.response);
        let { data } = error.response;
        setInstanceList([]);
        setSelectedInstance("");
        setToastInfo({ show: true, type: "error", message: data.message });
      });
  };

  const getCoursesForUser = (userId) => {
    axios
      .get(config.USER_COURSES_URL, {
        params: {
          user_id: userId,
        },
      })
      .then((res) => {
        setUserCourses([...res.data]);
      })
      .catch((error) => {
        console.log(error.response);
        let { data } = error.response;
        setUserCourses([]);
        setToastInfo({ show: true, type: "error", message: data.message });
      });
  };

  const addCourseForUser = () => {
    let requestBody;
    const id = splitValues(selectedCourse, "id");
    const type = splitValues(selectedCourse, "other");
    if (selectedCourse) {
      if (type === "COHORT") {
        requestBody = {
          course_id: id,
          user_id: moduleId,
          instance_id: splitValues(selectedInstance, "id"),
        };
      } else {
        requestBody = {
          course_id: id,
          user_id: moduleId,
        };
      }

      axios
        .post(config.BUY_COURSE_URL, requestBody)
        .then((res) => {
          getCoursesForUser(moduleId);
          setToastInfo({
            show: true,
            type: "success",
            message: "Course Added Successfully",
          });
        })
        .catch((error) => {
          console.log(error.response);
          let { data } = error.response;
          setToastInfo({ show: true, type: "error", message: data.message });
        });
    }
  };
  const handleCourseChange = (value) => {
    const type = splitValues(value, "other");
    const id = splitValues(value, "id");
    setSelectedCourse(value);
    setStatusBtn(false);
    if (type === "COHORT") getInstancesForCourse(id);
    else if (type === "SELF_PACED") {
      setInstanceList([]);
      setSelectedInstance("");
    } else {
      setInstanceList([]);
      setSelectedInstance("");
      setToastInfo({
        show: true,
        type: "error",
        message: "Select other Course",
      });
    }
  };
  const handleInstanceChange = (value) => {
    const available = splitValues(value, "other");
    setSelectedInstance(value);
    setStatusBtn(false);
    available === 0 &&
      setToastInfo({
        show: true,
        type: "error",
        message: "Select other Time Slot as this slot is already full",
      }) &&
      setStatusBtn(true);
  };
  const splitValues = (arr, value) => {
    switch (value) {
      case "other":
        return arr.split("^")[1];
      case "id":
        return arr.split("^")[0];
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastInfo.show}
        autoHideDuration={6000}
        onClose={() =>
          setToastInfo({
            show: false,
            type: "",
            message: "",
          })
        }
      >
        <Alert
          onClose={() =>
            setToastInfo({
              show: false,
              type: "",
              message: "",
            })
          }
          severity={toastInfo.type}
        >
          {toastInfo.message}
        </Alert>
      </Snackbar>
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: "bolder" }}
            variant="h5"
            gutterBottom
          >
            Select Course for User
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            fullWidth
            value={selectedCourse || ""}
            onChange={(e) => handleCourseChange(e.target.value)}
          >
            <MenuItem>Select choice</MenuItem>
            {courseList.map(({ title, value, type }) => (
              <MenuItem
                value={`${value}^${type}`}
              >{`${title} (${type})`}</MenuItem>
            ))}
          </Select>
        </Grid>
        {instanceList.length > 0 && (
          <Grid item xs={4}>
            <Select
              fullWidth
              value={selectedInstance || ""}
              onChange={(e) => handleInstanceChange(e.target.value)}
            >
              <MenuItem>Select choice</MenuItem>
              {instanceList.map(({ id, from, to, slots_available }) => (
                <MenuItem value={`${id}^${slots_available}`}>
                  <Moment format="Do MMM (hh:mm A)">{from}</Moment> {"-"}{" "}
                  <Moment format="Do MMM (hh:mm A)">{to}</Moment>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={4}>
          <Button
            onClick={addCourseForUser}
            variant="contained"
            color="primary"
            disabled={addCourseBtn}
          >
            Add Course
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: "bolder" }}
            variant="h5"
            gutterBottom
          >
            User Selected Courses
          </Typography>
        </Grid>
        {userCourses.length > 0 ? (
          userCourses.map(({ course_name, course_type }) => (
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" color="textSecondary" gutterBottom>
                    {`${course_name} (${course_type})`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={3}>
            <Typography variant="h6" component="h2">
              No Courses Selected for this user
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
