import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DocumentPicker from "./DocumentPicker";
import axios from "axios";
import config from "../commons/config";

export default ({
  docPickerKey,
  initialID,
  setDocData,
  collection,
  initialKey,
  getLabel,
  urlKey,
}) => {
  const [cloneInput, showCloneInput] = useState(false);
  const navigate = useHistory();

  const documentPickerChange = (newDoc, value, key) => {
    setDocData((document) => ({
      ...document,
      [key]: newDoc,
    }));
    if (key === "clone_course") cloneSelectedCourse(value.id);
  };
  const cloneSelectedCourse = (courseId) => {
    axios.post(config.CLONE_COURSE_URL, { course_id: courseId }).then((res) => {
      navigate.push(`/${urlKey}/edit/${res.data.clone_course_id}`);
    });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={4}
      alignItems="center"
      style={{ marginTop: "0.5em" }}
    >
      <Grid item xs={3}>
        <Button onClick={() => showCloneInput(!cloneInput)} variant="contained">
          Clone from Course
        </Button>
      </Grid>
      {cloneInput && (
        <Grid item xs={9}>
          <DocumentPicker
            key={docPickerKey}
            collection={collection}
            initialID={initialID}
            locked={false}
            initialKey={initialKey}
            getLabel={getLabel}
            handleChange={documentPickerChange}
          />
        </Grid>
      )}
    </Grid>
  );
};
