import config from "./config";

const collections = [
  {
    name: "Categories",
    urlKey: "categories",
    moduleName: "categories",
    enableEdit: true,
    enableCreate: true,
    defaultValues: {
      image: null,
      name: null,
      mainColor: null,
      fromColor: null,
      toColor: null,
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image",
        type: "Image",
      },
      {
        columnName: "Name",
        keyName: "name",
        width: "30%",
        type: "String",
      },
      {
        columnName: "Main color",
        keyName: "color",
        type: "String",
      },
      {
        columnName: "From Color",
        keyName: "fromColor",
        type: "String",
      },
      {
        columnName: "To Color",
        keyName: "toColor",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Image",
        key: "image",
        type: "Image",
        required: true,
        folder: "/categories/",
        gridRow: "12",
      },
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Main color",
        key: "color",
        type: "String",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "From color",
        key: "fromColor",
        type: "String",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "To color",
        key: "toColor",
        type: "String",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "Sort Order",
        key: "sort_order",
        type: "Integer",
        gridRow: "3",
      },
    ],
  },
  {
    name: "Self-paced Courses",
    urlKey: "self-paced-courses",
    moduleName: "courses",
    filterBy: {
      key: "type",
      value: "SELF_PACED",
    },
    enableEdit: true,
    enableCreate: true,
    hasComingSoon: true,
    defaultValues: {
      image: null,
      name: null,
      description: null,
      category: null,
      assessment: null,
      instructor: null,
      goals: [],
      worksheets: [],
      modules: [],
      sort_order: null,
      type: "SELF_PACED",
      isFree: false,
      product_id: null,
      reviews: [],
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image",
        type: "Image",
      },
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Category",
        keyName: "category",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Instructor",
        keyName: "instructor",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Assessment",
        keyName: "assessment",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Coming Soon",
        keyName: "coming_soon",
        type: "Boolean",
      },
      {
        columnName: "product id",
        keyName: "product_id",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Clone from Course",
        key: "clone_course",
        collectionName: "courses",
        type: "CloneCourseSection",
        required: false,
        getLabel: (doc) => `${doc.name} (${doc.type})`,
        gridRow: "10",
      },
      {
        displayName: "Image",
        key: "image",
        type: "Image",
        required: true,
        folder: "/instructors/",
        gridRow: "12",
      },
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Description",
        key: "description",
        type: "String",
        multiline: true,
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Is Free",
        key: "is_free",
        type: "Boolean",
        gridRow: "3",
      },
      {
        displayName: "Type",
        key: "type",
        type: "Radio",
        required: true,
        values: [
          {
            key: "SELF_PACED",
            value: "Self Paced",
          },
          {
            key: "COHORT",
            value: "Cohort",
          },
        ],
        gridRow: "3",
      },
      {
        displayName: "Product id",
        key: "product_id",
        type: "String",
        gridRow: "6",
      },
      {
        displayName: "Price",
        key: "price",
        type: "Integer",
        gridRow: "4",
      },
      {
        displayName: "Category",
        key: "category",
        collectionName: "categories",
        type: "OneToOneReference",
        required: true,
        gridRow: "4",
      },
      {
        displayName: "Assessment",
        key: "assessment",
        collectionName: "assessments",
        type: "OneToOneReference",
        gridRow: "4",
      },
      {
        displayName: "Tags",
        key: "tags",
        collectionName: "tags",
        type: "ManyToManyReference",
        required: true,
        gridRow: "7",
      },
      {
        displayName: "Sort Order",
        key: "sort_order",
        type: "Integer",
        gridRow: "2",
      },
      {
        displayName: "Instructor",
        key: "instructor",
        collectionName: "instructor",
        type: "OneToOneReference",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "Goals",
        addLabel: "Add new Goal",
        key: "goals",
        type: "SyncList",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Worksheets",
        addLabel: "Add new worksheet",
        key: "worksheets",
        type: "SyncList",
        valueType: "Image",
        meta: {
          folder: "/courses/",
        },
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Reviews",
        addLabel: "Add a new review",
        key: "Reviews",
        type: "SyncList",
        nested: true,
        required: true,
        headingKey: "review",
        defaultValues: {
          review: null,
          reviewer: null,
          reviewer_image: null,
        },
        inputFields: [
          {
            displayName: "Review",
            key: "review",
            type: "String",
            required: true,
          },
          {
            displayName: "Reviewer",
            key: "reviewer",
            type: "String",
            required: true,
          },
          {
            displayName: "Image",
            key: "reviewer_image",
            type: "Image",
            folder: "/reviews/",
          },
        ],
        gridRow: "12",
      },
      {
        name: "Modules",
        moduleName: "modules",
        displayName: "Modules",
        key: "modules",
        type: "ManyToOneReference",
        parentRefKey: "course",
        parentRefCollection: "courses",
        postDataPersistanceURL: config.MODULE_THUMBNAIL_URL,
        getTitle: (doc) => {
          return doc && doc.title;
        },
        addButton: "Add Module",
        defaultValues: {
          video_url: null,
          video_thumbnail: null,
          title: null,
          description: null,
          tasks: [],
          isFree: false,
          showOnEngage: false,
          worksheets: [],
        },
        inputFields: [
          {
            displayName: "Upload Video",
            key: "video_url",
            type: "Video",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Video Thumbnail",
            key: "video_thumbnail",
            type: "Image",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Upload Audio",
            key: "audio_url",
            type: "Video",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Audio Thumbnail",
            key: "audio_thumbnail",
            type: "Image",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Is free",
            key: "isFree",
            type: "Boolean",
            gridRow: 3,
          },
          {
            displayName: "Show on Engage",
            key: "showOnEngage",
            type: "Boolean",
            gridRow: 3,
          },

          {
            displayName: "Order",
            key: "order",
            type: "Integer",
            required: true,
            gridRow: 3,
          },
          {
            displayName: "Length in Mins",
            key: "length_in_mins",
            type: "Integer",
            required: true,
            gridRow: 3,
          },
          {
            displayName: "Title",
            key: "title",
            type: "String",
            required: true,
            gridRow: 5,
          },
          {
            displayName: "Description",
            key: "description",
            type: "String",
            gridRow: 7,
          },
          {
            displayName: "Tasks",
            addLabel: "Add new task",
            key: "tasks",
            type: "SyncList",
            required: true,
            gridRow: 5,
          },
          {
            displayName: "Worksheets",
            addLabel: "Add new worksheet",
            key: "worksheets",
            type: "SyncList",
            valueType: "Image",
            meta: {
              folder: "/courses/",
            },
            required: true,
            gridRow: 7,
          },
        ],
      },
    ],
  },
  {
    name: "Cohort Courses",
    urlKey: "cohort-courses",
    moduleName: "courses",
    filterBy: {
      key: "type",
      value: "COHORT",
    },
    enableEdit: true,
    enableCreate: true,
    hasComingSoon: true,
    defaultValues: {
      image: null,
      name: null,
      description: null,
      category: null,
      assessment: null,
      instructor: null,
      goals: [],
      modules: [],
      sort_order: null,
      rewards: [],
      worksheets: [],
      type: "COHORT",
      is_free: false,
      product_id: null,
      reviews: [],
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image",
        type: "Image",
      },
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Category",
        keyName: "category",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Instructor",
        keyName: "instructor",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Assessment",
        keyName: "assessment",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Product id",
        keyName: "product_id",
        type: "String",
      },
      {
        columnName: "Coming Soon",
        keyName: "coming_soon",
        type: "Boolean",
      },
    ],
    inputFields: [
      {
        displayName: "Image",
        key: "image",
        type: "Image",
        required: true,
        folder: "/instructors/",
        gridRow: "12",
      },
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Description",
        key: "description",
        type: "String",
        multiline: true,
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Type",
        key: "type",
        type: "Radio",
        required: true,
        values: [
          {
            key: "SELF_PACED",
            value: "Self Paced",
          },
          {
            key: "COHORT",
            value: "Cohort",
          },
        ],
        gridRow: "3",
      },
      {
        displayName: "Is Free",
        key: "is_free",
        type: "Boolean",
        gridRow: "2",
      },
      {
        displayName: "Product id",
        key: "product_id",
        type: "String",
        gridRow: "7",
      },
      {
        displayName: "Price",
        key: "price",
        type: "Integer",
        gridRow: "4",
      },
      {
        displayName: "Category",
        key: "category",
        collectionName: "categories",
        type: "OneToOneReference",
        required: true,
        gridRow: "4",
      },
      {
        displayName: "Assessment",
        key: "assessment",
        collectionName: "assessments",
        type: "OneToOneReference",
        gridRow: "4",
      },
      {
        displayName: "Tags",
        key: "tags",
        collectionName: "tags",
        type: "ManyToManyReference",
        required: true,
        gridRow: "7",
      },
      {
        displayName: "Sort Order",
        key: "sort_order",
        type: "Integer",
        gridRow: "2",
      },
      {
        displayName: "Instructor",
        key: "instructor",
        collectionName: "instructor",
        type: "OneToOneReference",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "Goals",
        addLabel: "Add new Goal",
        key: "goals",
        type: "SyncList",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Rewards",
        addLabel: "Add reward image",
        key: "rewards",
        type: "SyncList",
        valueType:"Image",
        required: true,
        meta: {
          folder: "/cohort-courses-rewards/"
        },
        gridRow: "12",
      },
      {
        displayName: "Worksheets",
        addLabel: "Add new worksheet",
        key: "worksheets",
        type: "SyncList",
        valueType: "Image",
        meta: {
          folder: "/courses/",
        },
        required: true,
      },
      {
        displayName: "Reviews",
        addLabel: "Add a new review",
        key: "Reviews",
        type: "SyncList",
        nested: true,
        required: true,
        headingKey: "review",
        defaultValues: {
          review: null,
          reviewer: null,
          reviewer_image: null,
        },
        inputFields: [
          {
            displayName: "Review",
            key: "review",
            type: "String",
            required: true,
          },
          {
            displayName: "Reviewer",
            key: "reviewer",
            type: "String",
            required: true,
          },
          ,
          {
            displayName: "Image",
            key: "reviewer_image",
            type: "Image",
            folder: "/reviews/",
          },
        ],
        gridRow: "12",
      },
      {
        name: "Cohort batches",
        moduleName: "cohort-instances",
        displayName: "Cohort batches",
        key: "cohort-instances",
        type: "ManyToOneReference",
        parentRefKey: "course",
        parentRefCollection: "courses",
        addButton: "Add Batch",
        getTitle: (doc) => {
          if (!doc.from || !doc.to) return "Adding new batch";
          if (doc.from.toDate && doc.to.toDate) {
            return `${doc.from
              .toDate()
              .toDateString()} to ${doc.to.toDate().toDateString()}`;
          }
          if (doc.from.toDateString && doc.to.toDateString) {
            return `${doc.from.toDateString()} to ${doc.to.toDateString()}`;
          }
        },
        defaultValues: {
          from: null,
          to: null,
          total_slots: 0,
          whatsapp_link: null,
        },
        inputFields: [
          {
            displayName: "From date",
            key: "from",
            type: "Date",
          },
          {
            displayName: "To date",
            key: "to",
            type: "Date",
          },
          {
            displayName: "Total slots",
            key: "total_slots",
            type: "Integer",
          },
          {
            displayName: "Whatsapp link",
            key: "whatsapp_link",
            type: "String",
          },
        ],
      },
      {
        name: "Modules",
        moduleName: "modules",
        displayName: "Modules",
        key: "modules",
        type: "ManyToOneReference",
        parentRefKey: "course",
        parentRefCollection: "courses",
        postDataPersistanceURL: config.MODULE_THUMBNAIL_URL,
        getTitle: (doc) => {
          return doc && doc.title;
        },
        addButton: "Add Module",
        defaultValues: {
          video_url: null,
          video_thumbnail: null,
          title: null,
          description: null,
          tasks: [],
          isFree: false,
          worksheets: [],
          showOnEngage: false,
        },
        inputFields: [
          {
            displayName: "Upload Video",
            key: "video_url",
            type: "Video",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Video Thumbnail",
            key: "video_thumbnail",
            type: "Image",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Upload Audio",
            key: "audio_url",
            type: "Video",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Audio Thumbnail",
            key: "audio_thumbnail",
            type: "Image",
            folder: "/modules/",
            gridRow: 6,
          },
          {
            displayName: "Is free",
            key: "isFree",
            type: "Boolean",
            gridRow: 3,
          },
          {
            displayName: "Show on Engage",
            key: "showOnEngage",
            type: "Boolean",
            gridRow: 3,
          },
          {
            displayName: "Order",
            key: "order",
            type: "Integer",
            required: true,
            gridRow: 3,
          },
          {
            displayName: "Length in Mins",
            key: "length_in_mins",
            type: "Integer",
            required: true,
            gridRow: 3,
          },
          {
            displayName: "Title",
            key: "title",
            type: "String",
            required: true,
            gridRow: 5,
          },
          {
            displayName: "Description",
            key: "description",
            type: "String",
            gridRow: 7,
          },
          {
            displayName: "Tasks",
            addLabel: "Add new task",
            key: "tasks",
            type: "SyncList",
            required: true,
            gridRow: 5,
          },
          {
            displayName: "Worksheets",
            addLabel: "Add new worksheet",
            key: "worksheets",
            type: "SyncList",
            valueType: "Image",
            meta: {
              folder: "/courses/",
            },
            required: true,
            gridRow: 7,
          },
        ],
      },
    ],
  },
  {
    name: "Instructors",
    urlKey: "instructors",
    moduleName: "instructor",
    enableEdit: true,
    enableCreate: true,
    defaultValues: {
      image: null,
      name: null,
      profession: null,
      designation: null,
      socialHandles: [],
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image",
        type: "Image",
      },
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Profession",
        keyName: "profession",
        type: "String",
      },
      {
        columnName: "Designation",
        keyName: "designation",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Image",
        key: "image",
        type: "Image",
        required: true,
        folder: "/instructors/",
        gridRow: "12",
      },
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "4",
      },
      {
        displayName: "Profession",
        key: "profession",
        type: "String",
        required: true,
        gridRow: "4",
      },
      {
        displayName: "Designation",
        key: "designation",
        type: "String",
        required: true,
        gridRow: "4",
      },
      {
        displayName: "Description",
        key: "intro",
        type: "String",
        required: true,
        multiline: true,
        gridRow: "12",
      },
      {
        displayName: "Social media handles",
        addLabel: "Add new social media handle",
        key: "socialHandles",
        type: "SyncList",
        nested: true,
        required: true,
        headingKey: "type",
        defaultValues: {
          type: null,
          url: null,
        },
        inputFields: [
          {
            displayName: "Media type",
            key: "type",
            type: "Select",
            choices: [
              {
                label: "Facebook",
                type: "FACEBOOK",
              },
              {
                label: "Twitter",
                type: "TWITTER",
              },
              {
                label: "Instagram",
                type: "INSTAGRAM",
              },
            ],
            required: true,
          },
          {
            displayName: "URL",
            key: "url",
            type: "String",
            required: true,
          },
        ],
      },
    ],
  },
  {
    name: "Authors",
    urlKey: "authors",
    moduleName: "author",
    enableEdit: true,
    enableCreate: true,
    defaultValues: {
      image: null,
      name: null,
      designation: null,
      description: null,
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image",
        type: "Image",
      },
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Designation",
        keyName: "designation",
        type: "String",
      },
      {
        columnName: "Description",
        keyName: "description",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Image",
        key: "image",
        type: "Image",
        required: true,
        folder: "/authors/",
        gridRow: "12",
      },
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "6",
      },
      {
        displayName: "Designation",
        key: "designation",
        type: "String",
        required: true,
        gridRow: "6",
      },
      {
        displayName: "Description",
        key: "description",
        type: "String",
        required: true,
        multiline: true,
        gridRow: "12",
      },
    ],
  },
  {
    name: "Videos",
    urlKey: "videos",
    moduleName: "videos",
    enableEdit: true,
    enableCreate: true,
    defaultValues: {
      title: null,
      length_in_mins: null,
      description: null,
      worksheets: [],
      tags: [],
    },
    columnOrder: [
      {
        columnName: "Thumbnail",
        keyName: "thumbnail",
        type: "Image",
      },
      {
        columnName: "Title",
        keyName: "title",
        type: "String",
      },
      {
        columnName: "Length",
        keyName: "length_in_mins",
        type: "String",
      },
      {
        columnName: "Description",
        keyName: "description",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Video",
        key: "url",
        type: "Video",
        required: true,
        folder: "/engage_videos/",
        gridRow: "6",
      },
      {
        displayName: "Thumbnail",
        key: "thumbnail",
        type: "Image",
        required: true,
        folder: "/engage_videos/",
        gridRow: "6",
      },
      {
        displayName: "Title",
        key: "title",
        type: "String",
        required: true,
        gridRow: "12",
      },
      {
        displayName: "Length",
        key: "length_in_mins",
        type: "Integer",
        required: true,
        gridRow: "2",
      },
      {
        displayName: "Tags",
        key: "tags",
        collectionName: "tags",
        type: "ManyToManyReference",
        required: true,
        gridRow: "10",
      },
      {
        displayName: "Description",
        key: "description",
        type: "String",
        required: true,
        multiline: true,
        gridRow: "12",
      },
      {
        displayName: "Worksheets",
        addLabel: "Add new worksheet",
        key: "worksheets",
        type: "SyncList",
        valueType: "Image",
        meta: {
          folder: "/videos/",
        },
        required: true,
        gridRow: "12",
      },
    ],
  },
  {
    name: "Assessments",
    urlKey: "assessments",
    moduleName: "assessments",
    enableEdit: true,
    enableCreate: true,
    dontShowRoute: true,
    exportData: true,
    reportingURL: config.ASSESSMENT_REPORTING_URL,
    columnOrder: [
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Category",
        keyName: "category",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Coming Soon",
        keyName: "coming_soon",
        type: "Boolean",
      },
    ],
  },
  {
    name: "Book reviews",
    urlKey: "book-reviews",
    moduleName: "book-reviews",
    enableEdit: true,
    enableCreate: true,
    hasComingSoon: true,
    defaultValues: {
      image_url: null,
      title: null,
      category: null,
      subtitle: null,
      sort_order: null,
      purchase_url: null,
      description: null,
      sections: [],
      isFree: false,
      feature_on_home: false,
      new_book_tag: false,
    },
    columnOrder: [
      {
        columnName: "Image",
        keyName: "image_url",
        type: "Image",
      },
      {
        columnName: "Title",
        keyName: "title",
        type: "String",
      },
      {
        columnName: "Subtitle",
        keyName: "subtitle",
        type: "String",
      },
      {
        columnName: "Purchase url",
        keyName: "purchase_url",
        type: "String",
      },
      {
        columnName: "Coming Soon",
        keyName: "coming_soon",
        type: "Boolean",
      },
      {
        columnName: "Category",
        keyName: "category",
        type: "Child",
        display: "name",
      },
      {
        columnName: "Is free",
        keyName: "isFree",
        type: "Boolean",
      },
    ],
    inputFields: [
      {
        displayName: "Image",
        key: "image_url",
        type: "Image",
        required: true,
        folder: "/book_reviews/",
        gridRow: "12",
      },
      {
        displayName: "Title",
        key: "title",
        type: "String",
        required: true,
        gridRow: "7",
      },
      {
        displayName: "Category",
        key: "category",
        collectionName: "categories",
        type: "OneToOneReference",
        required: true,
        gridRow: "5",
      },
      {
        displayName: "Subtitle",
        key: "subtitle",
        type: "String",
        required: true,
        gridRow: "7",
      },
      {
        displayName: "Purchase url",
        key: "purchase_url",
        type: "String",
        required: true,
        gridRow: "5",
      },
      {
        displayName: "Description",
        key: "description",
        type: "String",
        required: true,
        multiline: true,
        gridRow: "12",
      },
      {
        displayName: "Tags",
        key: "tags",
        collectionName: "tags",
        type: "ManyToManyReference",
        required: true,
        gridRow: "6",
      },
      {
        displayName: "Author",
        key: "authors",
        collectionName: "author",
        type: "ManyToManyReference",
        required: true,
        gridRow: "6",
      },
      {
        displayName: "Feature on Home",
        key: "feature_on_home",
        type: "Boolean",
        required: true,
        gridRow: "3",
      },
      {
        displayName: "Display New Tag",
        key: "new_book_tag",
        type: "Boolean",
        gridRow: "3",
      },
      {
        displayName: "Is free",
        key: "isFree",
        type: "Boolean",
        gridRow: "3",
      },
      {
        displayName: "Sort Order",
        key: "sort_order",
        type: "Integer",
        gridRow: "2",
      },
      {
        displayName: "Sections",
        addLabel: "Add new section",
        key: "sections",
        type: "SyncList",
        nested: true,
        required: true,
        headingKey: "title",
        gridRow: "12",
        defaultValues: {
          video_url: null,
          title: null,
          description: null,
          tasks: [],
        },
        inputFields: [
          {
            displayName: "Section title",
            key: "title",
            type: "String",
            required: true,
          },
          {
            displayName: "Section subtitle",
            key: "subtitle",
            type: "String",
            required: true,
          },
          {
            displayName: "Contents",
            addLabel: "Add new Content",
            key: "content",
            type: "SyncList",
            nested: true,
            required: true,
            headingKey: "text",
            defaultValues: {
              title: null,
              text: null,
            },
            inputFields: [
              {
                displayName: "Content title",
                key: "title",
                type: "String",
              },
              {
                displayName: "Content text",
                key: "text",
                type: "String",
                required: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Tags",
    urlKey: "tags",
    moduleName: "tags",
    enableEdit: true,
    enableCreate: true,
    defaultValues: {
      name: null,
      book_reviews: [],
      videos: [],
      courses: [],
    },
    columnOrder: [
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
    ],
    inputFields: [
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: "3",
      },
    ],
  },
  {
    name: "User profiles",
    urlKey: "user-profiles",
    moduleName: "user-profiles",
    enableEdit: true,
    backendUrl: config.USER_PROFILE_URL,
    defaultValues: {
      name: null,
      is_admin: false,
      is_super_admin: false,
      phoneNumber: null,
    },
    csvDownloadUrl: config.USER_INFO_CSV,
    columnOrder: [
      {
        columnName: "Id",
        keyName: "objectID",
        type: "String",
      },
      {
        columnName: "Name",
        keyName: "name",
        type: "String",
      },
      {
        columnName: "Email",
        keyName: "email",
        type: "String",
      },
      {
        columnName: "Phone Number",
        keyName: "phoneNumber",
        type: "String",
      },
      {
        columnName: "Is admin",
        keyName: "is_admin",
        type: "Boolean",
      },
      {
        columnName: "Has admin panel access",
        keyName: "is_super_admin",
        type: "Boolean",
      },
    ],
    inputFields: [
      {
        displayName: "Name",
        key: "name",
        type: "String",
        required: true,
        gridRow: 6,
      },
      {
        displayName: "Is admin",
        key: "is_admin",
        type: "Boolean",
        gridRow: 6,
      },
      {
        displayName: "Current Subscription",
        key: "current_subscription",
        extraKey: "name",
        type: "String",
        disabled: true,
        gridRow: 6,
      },
      {
        displayName: "Has admin panel access",
        key: "is_super_admin",
        type: "Boolean",
        gridRow: 6,
      },
      {
        displayName: "Select Course",
        key: "is_super_admin",
        type: "Custom Select",
        gridRow: 12,
      },
    ],
  },
];

export default collections;
