import firebase from "firebase";

let config;

if (process.env.REACT_APP_ENV === "PRODUCTION") {
  config = {
    apiKey: "AIzaSyAKDEg3HeV7sIXmK7pr28e8K18Fev81SgQ",
    authDomain: "masterlife-app.firebaseapp.com",
    databaseURL: "https://masterlife-app.firebaseio.com",
    projectId: "masterlife-app",
    storageBucket: "masterlife-app.appspot.com",
    messagingSenderId: "290369259664",
    appId: "1:290369259664:web:d37f354298a6c57759f015",
    measurementId: "G-HQ77KBWKCB",
  };
} else {
  config = {
    apiKey: "AIzaSyCODzSo2sZEPNorq2WNxtvVajHyddYMPZ8",
    authDomain: "masterlife-staging.firebaseapp.com",
    databaseURL: "https://masterlife-staging.firebaseio.com",
    projectId: "masterlife-staging",
    storageBucket: "masterlife-staging.appspot.com",
    messagingSenderId: "571190303911",
    appId: "1:571190303911:web:c75cf81e4e7ed724aa0f82",
    measurementId: "G-353YWS4YKR",
  };
}

const uiConfig = {
  signInSuccessUrl: "/",
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: "image",
        size: "invisible",
        badge: "bottomleft",
      },
      defaultCountry: "IN",
    },
  ],
};

const firebaseApp = firebase.initializeApp(config);
const firestore = firebaseApp.firestore();

export { firebaseApp, uiConfig, firestore };
