import React, { useState, useEffect } from "react";
import { firestore } from "./../firebase";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import axios from "../utils/Axios";
import { AuthContext } from "../context/AuthContext";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { startHydration } from "./../commons/FirebaseRefHydrate";
import { useContext } from "react";

import { useHistory } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import algoLogo from "../download.png";

import config from "../commons/config";

export default withRouter(
  ({
    name,
    urlKey,
    moduleName,
    columnOrder,
    enableEdit,
    enableCreate,
    history,
    filterBy: { key, value } = {},
    backendUrl,
    exportData,
    reportingURL,
    csvDownloadUrl,
  }) => {
    const [documents, setDocuments] = useState([]);
    const [docsTotal, setDocsTotal] = useState(0);

    const [docHead, setDocHead] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState("");

    const [searchMode, setSearchMode] = useState(false);
    const [csvData, setCsvData] = useState("");

    const { loggedInUser } = useContext(AuthContext);

    const historyRouter = useHistory();

    useEffect(() => {
      if (urlKey === "user-profiles") {
        let x = [];

        columnOrder.map((cpx) => {
          x.push({
            label: cpx.columnName,
            name: cpx.keyName,
            options: {
              filter: false,
              sort: false,
            },
          });
        });
        setDocHead([...x]);
        searchApiDebounced();
      } else getUserDocs();
    }, []);

    const handleChangeRowsPerPage = (event) => {
      console.log(event);
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleChangePage = (event) => {
      console.log(event);
      setPage(event);
      if (event > page) {
        if ((event + 1) * rowsPerPage > documents.length) {
          if (searchMode)
            searchAlgoliaIndex(searchQuery, rowsPerPage, event, true);
          else searchAlgoliaIndex("", rowsPerPage, event, true);
        }
      }
    };
    const downloadUsersCSV = async () => {
      const userProfileDoc = await firestore
        .collection("user-profiles")
        .doc(loggedInUser.uid)
        .get();

      if (
        !userProfileDoc.data().email ||
        !userProfileDoc.data().email.length === 0
      ) {
        alert("Please save a valid email address on the HOME tab");
        return;
      }

      axios()
        .get(csvDownloadUrl, { params: { email: userProfileDoc.data().email } })
        .then((res) =>
          alert(
            `An email will be sent to ${
              userProfileDoc.data().email
            } shortly. This may take a while if the dataset is large.`
          )
        );
    };
    const searchAlgoliaIndex = async (
      query = "",
      noOfRows = rowsPerPage,
      pageNo = 0,
      pagination = false
    ) => {
      if (query === null) query = "";
      if (query !== "") setSearchMode(true);
      else if (query === "") setSearchMode(false);
      if (pageNo === 0) setPage(0) && setDocuments([]);

      setSearchQuery(query);
      try {
        const response = axios().get(config.SEARCH_USER_URL, {
          params: { q: query, perPage: noOfRows, page: pageNo },
        });
        const data = (await response).data;
        if (data.hits.length > 0) {
          console.log(data.total_docs);
          if (pagination) {
            const prevData = [...documents];
            setDocuments([...prevData, ...data.hits]);
          } else setDocuments([...data.hits]);

          setDocsTotal(data.total_docs);
        }
      } catch (e) {
        console.log(e);
        setSearchMode(false);
        setDocuments([]);
        setSearchQuery("");
        setDocsTotal(0);
        setPage(0);
      }
    };

    const searchApiDebounced = AwesomeDebouncePromise(searchAlgoliaIndex, 500);
    const getUserDocs = () => {
      console.log(columnOrder);
      let x = [];
      columnOrder.map((cpx) => {
        x.push({
          label: cpx.columnName,
          name: cpx.keyName,
          options: {
            filter: false,
            sort: false,
          },
        });
      });
      setDocHead([...x]);
      console.log(x);
      if (backendUrl) {
        axios()
          .get(backendUrl)
          .then(({ data }) => {
            setDocuments(() => data);
          });
        return;
      }
      const hydrationManager = startHydration();
      let query = firestore.collection(moduleName);
      if (key && value) {
        query = query.where(key, "==", value);
      }
      query = query.get();
      query.then(async (querySnapshot) => {
        let newDocuments = [];
        newDocuments = querySnapshot.docs
          .map(function (doc) {
            const document = doc.data();
            const id = doc.id;
            hydrationManager.hydrateAllRefs(document, id);
            return { id, ...document };
          })
          .sort((a, b) => {
            if (a.coming_soon) {
              return 1;
            }
            return -1;
          });
        hydrationManager
          .getAllHydratedRefs(newDocuments)
          .then((updatedDocs) => setDocuments(() => updatedDocs));
      });
    };
    const changeRoute = (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      historyRouter.push(`/${urlKey}/edit/${rowData[0]}/`);
    };
    const handleCSV = async (assessmentId) => {
      const userProfileDoc = await firestore
        .collection("user-profiles")
        .doc(loggedInUser.uid)
        .get();

      if (
        !userProfileDoc.data().email ||
        !userProfileDoc.data().email.length === 0
      ) {
        alert("Please save a valid email address on the HOME tab");
        return;
      }

      axios()
        .get(reportingURL + assessmentId + `/${userProfileDoc.data().email}`)
        .then((res) =>
          alert(
            `An email will be sent to ${
              userProfileDoc.data().email
            } shortly. This may take a while if the dataset is large.`
          )
        );
    };
    const userTableOptions = {
      search: true,
      pagination: true,
      page: page,
      print: false,
      filter: false,
      download: false,
      onChangePage: handleChangePage,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [10, 25, 200],
      count: docsTotal,
      viewColumns: false,
      selectableRows: "none",
      onSearchClose: searchApiDebounced,
      customSearch: () => true,
      onSearchChange: searchApiDebounced,
      onRowClick: changeRoute,
    };
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h1>Collection: {name}</h1>
          {enableCreate && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => history.push(`/${moduleName}/create/`)}
            >
              Add new
            </Button>
          )}
        </Grid>
        {urlKey === "user-profiles" ? (
          <MUIDataTable
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img width={100} height={30} src={algoLogo} />
                <span>User Profiles</span>
                <CloudDownloadIcon
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={downloadUsersCSV}
                />
              </div>
            }
            columns={docHead}
            data={documents}
            options={userTableOptions}
          />
        ) : (
          <>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {columnOrder.map(({ columnName }) => (
                      <TableCell>{columnName}</TableCell>
                    ))}
                    {enableEdit && <TableCell>Edit</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((row) => {
                    const keys = Object.keys(row);
                    return (
                      <TableRow key={row.name}>
                        {columnOrder.map(
                          ({ keyName, type, display, nestedKey }) => {
                            let visibleElement;
                            if (row[keyName] == null) {
                              visibleElement = "";
                            }
                            switch (type) {
                              case "Image":
                                if (row[keyName] == null) {
                                  break;
                                }
                                visibleElement = (
                                  <img
                                    alt=""
                                    src={row[keyName]}
                                    height="100"
                                    width="100"
                                  />
                                );
                                break;
                              case "Child":
                                visibleElement = row[keyName]
                                  ? row[keyName][display]
                                  : "";
                                break;
                              case "Boolean":
                                visibleElement = row[keyName] ? "Yes" : "No";
                                break;
                              case "Nested":
                                visibleElement =
                                  row[keyName] && row[keyName][nestedKey]
                                    ? row[keyName][nestedKey]
                                    : "";
                                break;
                              default:
                                visibleElement = row[keyName];
                                break;
                            }
                            return (
                              <TableCell className="col-size">
                                {visibleElement}
                              </TableCell>
                            );
                          }
                        )}
                        {enableEdit && (
                          <TableCell>
                            <Link to={`/${urlKey}/edit/${row.id}/`}>
                              <EditSharpIcon />
                            </Link>
                          </TableCell>
                        )}
                        {exportData && (
                          <Button
                            variant="contained"
                            onClick={() => handleCSV(row.id)}
                          >
                            Export CSV
                          </Button>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    );
  }
);
