import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import collections from '../commons/collections';
import { AuthContext } from '../context/AuthContext';
import { NavLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
    staging:{
        backgroundColor:"red",
        padding:"10px"
    },
    navLink:{
        textDecoration:"none",
        color:"inherit"
    }
})

const SideNav = () => {

    const classes = useStyle();

    const { loggedInUser } = useContext(AuthContext);

    return loggedInUser ? (
        <Drawer
            variant="permanent"
            anchor="left"
        >
            <Divider />
            <List>
                { process.env.REACT_APP_ENV !== 'PRODUCTION' &&
                    <div className={classes.staging}>
                        <h4>STAGING</h4>
                    </div>
                }
                <NavLink to="/" className={classes.navLink}>
                    <ListItem button key="home">
                        <ListItemText primary="Home" />
                    </ListItem>
                </NavLink>
                {collections.map(({ name, urlKey }) => (
                    <NavLink to={`/${urlKey}`} className={classes.navLink}>
                        <ListItem button key={name}>
                            <ListItemText primary={name} />
                        </ListItem>
                    </NavLink>
                ))}
            </List>
        </Drawer>
    ) : <div></div>;
}

export default SideNav;